import React, { FC } from 'react';
import Image from 'next/image';
import Button, { ButtonStyle } from '../common/Button';
import Heading from '../common/Heading';
import { useRouter } from 'next/router';

export type BannerStyles =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'neutral'
    | 'success'
    | 'danger';

interface Props {
    bannerStyle?: BannerStyles;
    title: string;
    text?: string;
    actions?: {
        text: string;
        styledAs: ButtonStyle;
        href: string;
    }[];
    image?: StaticImageData;
}

const ContentCard: FC<Props> = ({
    bannerStyle = 'primary',
    title,
    text,
    actions,
    image,
}) => {
    const router = useRouter();

    return (
        <section
            className={`
                ${bannerStyle === 'primary' && 'bg-primary-100'}
                ${bannerStyle === 'secondary' && 'bg-secondary-100'}
                ${bannerStyle === 'tertiary' && 'bg-tertiary-100'}
                ${bannerStyle === 'neutral' && 'bg-neutral-100'}
                ${bannerStyle === 'success' && 'bg-success-100'}
                ${bannerStyle === 'danger' && 'bg-danger-100'}
                flex flex-col tablet:flex-row flex-wrap gap-md tablet:gap-4xl items-center justify-center p-md tablet:py-[7rem] tablet:px-[6rem]
            `}
        >
            <div className="flex-1 order-2 tablet:order-1">
                <Heading noTopMargin level="2">
                    {title}
                </Heading>
                {text && (
                    <p className="text-xsm-compact text-neutral-700">{text}</p>
                )}
                {actions &&
                    actions.map((action, index) => (
                        <Button
                            key={index}
                            onClick={() => router.push(action.href)}
                            className={`button button--${action.styledAs}`}
                        >
                            {action.text}
                        </Button>
                    ))}
            </div>
            {image && (
                <div className="flex-1 flex items-center justify-center order-1 tablet:order-2">
                    <Image src={image} alt={title} />
                </div>
            )}
        </section>
    );
};

export default ContentCard;

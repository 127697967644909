import withUserRoute from '@/components/auth/withUserRoute';
import Header from '@/components/global/Header';
import ContentCard from '@/components/marketing/ContentCard';
import ContentContainer from '@/components/marketing/ContentContainer';
import ItemsBar from '@/components/marketing/ItemsBar';
import { ssrWebsiteUserData } from '@/services/user.service';
import { GetServerSideProps, NextPage } from 'next';
import React from 'react';

import DeliveryBoxImage from '../assets/marketing/delivery-box.png';

export const getServerSideProps: GetServerSideProps = ssrWebsiteUserData;

const WebsiteHomePage: NextPage = () => {
    return (
        <div className="bg-white">
            <Header showSearch showSupportInfo />

            <ItemsBar />

            <ContentContainer>
                <ContentCard
                    title="NHS prescriptions delivered free right to your door!"
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit mauris, lorem sed faucibus ante risus elit pellentesque. Dignissim tortor cras purus adipiscing."
                    image={DeliveryBoxImage}
                />
            </ContentContainer>
        </div>
    );
};

export default withUserRoute(WebsiteHomePage);

import DeliveryTruck from '@/assets/icons/DeliveryTruck';
import RoundTickSmall from '@/assets/icons/RoundTickSmall';
import React from 'react';

const Item = ({ children }) => {
    return (
        <p className="flex items-center gap-base text-primary-700 font-thin text-2xsm-compact">
            <span className="w-[25px] flex-none">{children[0]}</span>
            {children[1]}
        </p>
    );
};

const ItemsBar = () => {
    return (
        <aside className="bg-primary-200 text-primary-700 py-md px-2xl overflow-scroll">
            <div className="max-w-desktop mx-auto flex items-center justify-around">
                <Item>
                    <DeliveryTruck />
                    Express Delivery Available
                </Item>
                <Item>
                    <RoundTickSmall />
                    Genuine UK medicines
                </Item>
                <Item>
                    <RoundTickSmall />
                    Lowest price gaurantee
                </Item>
                <Item>
                    <RoundTickSmall />
                    UK registered pharmacy
                </Item>
                <Item>
                    <RoundTickSmall />
                    Confidential &amp; discreet
                </Item>
            </div>
        </aside>
    );
};

export default ItemsBar;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApolloClientContext } from '@/contexts/ApolloClientProvider';
import { AppUserContext } from '@/contexts/AppUserProvider';
import { FC, useContext, useEffect } from 'react';

const withUserRoute: (WrappedComponent: FC) => (props: any) => JSX.Element =
    WrappedComponent => {
        return props => {
            const { setUser } = useContext(AppUserContext);
            const { setAuthToken } = useContext(ApolloClientContext);

            useEffect(() => {
                setAuthToken(props.jwtToken);
                setUser(props.userInfo);
            }, [props]);

            return <WrappedComponent {...props} />;
        };
    };

export default withUserRoute;
